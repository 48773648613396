import { useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ActionButton, InputField } from 'react-frontend-components';

import { api } from '../../api/api';
import { useTr, useValidated } from '../../utils/hooks';
import MainPageBox from '../common/MainPageBox';

export const PasswordResetDialog = () => {
    const { t } = useTranslation();
    const tr = useTr();
    const navigate = useNavigate();

    const email = useValidated('');

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [isDone, setDone] = useState(false);

    const handleResetPassword = async () => {
        if (!email.isValid()) {
            return;
        }

        email.submit();

        const result = await api.user.resetPassword(email.value);
        if (result.error) {
            result.errMessage && setErrorMessage(tr(result.errMessage));
            email.setErr(tr(result.error.formErr['email']));
            return;
        }

        setDone(true);
    };

    return (
        <MainPageBox
            hint={isDone ? t('label_password_reset_done') : t('label_password_reset_hint')}
            title={t('label_password_reset_title')}
            errorMessage={errorMessage}
        >
            {!isDone && (
                <React.Fragment>
                    <InputField
                        type='email'
                        color='secondary'
                        value={email.value}
                        onValueChange={(value: string, hasErr: boolean) => {
                            email.setValue(value, hasErr ? t('error_form_invalid_email') : undefined);
                        }}
                        onEnter={handleResetPassword}
                        label={t('label_email')}
                        size='small'
                        validation={(value, modified) => {
                            return !modified || /^.+@.+\.[a-zA-Z]{2,}$/.test(value);
                        }}
                        helperText={email.error}
                        autoFocus
                        sx={{ mt: 1 }}
                    />
                    <ActionButton
                        variant='contained'
                        onClick={handleResetPassword}
                        size='medium'
                        color='primary'
                        disabled={!email.isValid()}
                        fullWidth
                    >
                        {t('btn_reset')}
                    </ActionButton>
                </React.Fragment>
            )}
            <ActionButton
                variant={isDone ? 'contained' : undefined}
                onClick={() => navigate('/')}
                size='medium'
                color={isDone ? 'primary' : 'secondary'}
                sx={{ mt: 1 }}
            >
                {t('btn_back_to_home')}
            </ActionButton>
        </MainPageBox>
    );
};

export default PasswordResetDialog;
