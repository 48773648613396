import { Theme, ThemeProvider } from '@mui/material/styles';
import { lazy, Suspense, useEffect, useState } from 'react';
import { SupportedLanguages } from 'react-frontend-components/dist/utils/i18n';
import { useTranslation } from 'react-i18next';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { SamsonSDEThemeConfiguration } from 'samson';

import {
    NotificationContextProvider,
    ModalDialogContextProvider,
    LoadingIndicator,
    getSDEtheme,
} from 'react-frontend-components';

const PageNotFound = lazy(() => import('./pages/PageNotFound'));

const PageDevice = lazy(() => import('./pages/PageDevice'));
const PageDeviceRegistration = lazy(() => import('./pages/PageDeviceRegistration'));
const PageMain = lazy(() => import('./pages/PageMain'));
const PageMarkdown = lazy(() => import('./pages/PageMarkdown'));

import 'react-frontend-components/dist/style.css';
import 'samson/src/assets/icon/stylesheets/sam-awesome.css';
import { api } from './api/api';
import { LoadingSpinner, LogInDialog, PasswordResetDialog } from './components';
import { SignupCard } from './components/authentication/SignupCard';
import { AuthLayout } from './pages';
import { PageActivate } from './pages/PageActivate';

const App = () => {
    const { t, i18n } = useTranslation();

    const [theme, setTheme] = useState<Theme | undefined>(undefined);

    useEffect(() => {
        const initializeTheme = async () => {
            const activeTheme = getSDEtheme(i18n.language as SupportedLanguages, SamsonSDEThemeConfiguration, {
                ModalDialog: {
                    label_message_title: t('label_modal_dialog_show_messgage_dialog_title'),
                    label_message_ok: t('label_modal_dialog_ok'),
                    label_confirmation_title: t('label_modal_dialog_confirmation_dialog_title'),
                    label_confirmation_ok: t('label_modal_dialog_ok'),
                    label_confirmation_cancel: t('label_modal_dialog_cancel_button'),
                },
            });
            setTheme(activeTheme);

            activeTheme.SDE.i18n.initialize();
        };

        initializeTheme();
    }, [t, i18n]);

    if (!theme) {
        return <LoadingSpinner />;
    }

    async function contextLoader() {
        const user = await api.user.get(true);
        if (user.error) {
            if (user.error.status >= 500) {
                return { user: null, device: null, failure: true };
            }

            return {
                user: null,
                device: null,
                failure: false,
            };
        }

        const deviceInfo = await api.device.get();
        return {
            user: user.data,
            device: deviceInfo.data || null,
            failure: deviceInfo.error && deviceInfo.error.status >= 500,
        };
    }

    const router = createBrowserRouter([
        // accessible without login
        {
            path: '/',
            element: (
                <PageMain autoRedirect>
                    <LogInDialog />
                </PageMain>
            ),
            errorElement: <PageNotFound />,
            loader: contextLoader,
        },
        {
            path: '/activate/:type/:token',
            element: (
                <PageMain>
                    <PageActivate />
                </PageMain>
            ),
            loader: contextLoader,
        },
        {
            path: '/password-reset',
            element: (
                <PageMain>
                    <PasswordResetDialog />
                </PageMain>
            ),
            errorElement: <PageNotFound />,
            loader: contextLoader,
        },
        {
            path: '/signup',
            element: (
                <PageMain autoRedirect>
                    <SignupCard />
                </PageMain>
            ),
            errorElement: <PageNotFound />,
            loader: contextLoader,
        },

        // authentication required
        {
            path: '/device',
            element: (
                <AuthLayout wantDevice>
                    <PageDevice />
                </AuthLayout>
            ),
            loader: contextLoader,
        },
        {
            path: '/register',
            element: (
                <AuthLayout wantUser>
                    <PageDeviceRegistration />
                </AuthLayout>
            ),
            errorElement: <PageNotFound />,
            loader: contextLoader,
        },

        // static content
        {
            path: '/imprint',
            element: <PageMarkdown source='imprint' />,
            errorElement: <PageNotFound />,
        },
        {
            path: '/terms',
            element: <PageMarkdown source='terms' />,
            errorElement: <PageNotFound />,
        },
        {
            path: '/privacy',
            element: <PageMarkdown source='privacy' />,
            errorElement: <PageNotFound />,
        },
    ]);

    return (
        <ThemeProvider theme={theme!}>
            <ModalDialogContextProvider>
                <NotificationContextProvider>
                    <Suspense fallback={<LoadingIndicator isLoading={true} />}>
                        <RouterProvider router={router} />
                    </Suspense>
                </NotificationContextProvider>
            </ModalDialogContextProvider>
        </ThemeProvider>
    );
};

export default App;
