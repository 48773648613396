const keys = {
    csrfToken: 'csrf_token',
    sidebarCollapsed: 'sidebar_collapsed',
    currentRoute: 'route',
};

const saveBool = (key: string, state: boolean) => {
    localStorage.setItem(key, state ? 'true' : 'false');
};

export const getIsSidebarCollapsed = (): boolean => {
    return localStorage.getItem(keys.sidebarCollapsed) === 'true';
};

export const saveIsSidebarCollapsed = (isCollapsed: boolean) => {
    saveBool(keys.sidebarCollapsed, isCollapsed);
};

export const getCSRFtoken = (): string => {
    return localStorage.getItem(keys.csrfToken) || '-';
};

export const saveCSRFtoken = (token: number) => {
    localStorage.setItem(keys.csrfToken, `${token}`);
};

export const getCurrentRoute = (): string => {
    return localStorage.getItem(keys.currentRoute) || '/';
};

export const saveCurrentRoute = (route: string) => {
    localStorage.setItem(keys.currentRoute, route);
};
