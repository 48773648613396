import { BackendRequests, InputFormResult, ResponsePromise } from 'react-frontend-components';

import { getCSRFtoken, getCurrentRoute, saveCSRFtoken } from '../context';
import { CircuitData, CircuitHandlingData, DeviceInfo, EmptyObject, UserInfo } from './types';

const backend = BackendRequests(
    '',
    () => getCurrentRoute(),
    () => getCSRFtoken(),
);

const userURLs = {
    login: '/api/v1/mt/login',
    logout: '/api/v1/mt/logout',
    register: '/api/v1/mt/signup',
    activate: '/api/v1/account/activate',
    resetPassword: '/api/v1/mt/password-reset',
    me: '/api/v1/mt/me',
};

const deviceURLs = {
    circuits: '/api/v1/mt/device/circuits',
    get: '/api/v1/mt/device',
    getMeters: '/api/v1/mt/device/wmz',
    initialize: '/api/v1/mt/device/init',
    prepare: '/api/v1/mt/device/prepare',
    register: '/api/v1/mt/device/register',
};

export type ResponseEmptyPromise = ResponsePromise<EmptyObject>;

export const api = {
    activate: async function (typeID: string, token: string, customValue?: string): ResponseEmptyPromise {
        const data: Record<string, unknown> = { type: typeID, token: token };
        if (customValue !== undefined) {
            data['value0'] = customValue;
        }
        return await backend.PostForm(userURLs.activate, data);
    },
    user: {
        register: async function (email: string, password: string): ResponseEmptyPromise {
            return await backend.Post<EmptyObject>(userURLs.register, { email, password, language: 'de' });
        },
        login: async function (email: string, password: string, rememberMe: boolean): ResponsePromise<UserInfo> {
            return await backend.PostForm<UserInfo>(userURLs.login, { email, password, remember_me: rememberMe });
        },
        logout: async function (): ResponseEmptyPromise {
            return await backend.Post<EmptyObject>(userURLs.logout);
        },
        resetPassword: async function (email: string): ResponseEmptyPromise {
            return await backend.PostForm<EmptyObject>(userURLs.resetPassword, { email });
        },
        get: async function (noRedirect: boolean): ResponsePromise<UserInfo> {
            const content = await backend.Get<UserInfo>(userURLs.me, { noRedirectOn401: noRedirect });
            if (content.data) {
                saveCSRFtoken(content.data.token);
            }
            return content;
        },
        update: async function (data: InputFormResult): ResponsePromise<boolean> {
            const result = await backend.Post<{ email_sent: boolean }>(userURLs.me, data);
            if (result.data) {
                return { data: result.data.email_sent, errMessage: undefined, error: undefined };
            }
            return result;
        },
        delete: async function (): ResponseEmptyPromise {
            const result = await backend.Delete(userURLs.me);
            return result.data ? { data: {}, errMessage: undefined, error: undefined } : result;
        },
    },
    device: {
        get: async function (): ResponsePromise<DeviceInfo> {
            return await backend.Get<DeviceInfo>(deviceURLs.get, { noRedirectOn401: true });
        },
        getCircuit: async function (nr: number): ResponsePromise<CircuitData | null> {
            return await backend.Get(deviceURLs.circuits + '?key=' + nr);
        },
        getMeters: async function (): ResponsePromise<number[] | null> {
            return await backend.Get<number[] | null>(deviceURLs.getMeters);
        },
        prepare: async function (mac: string): ResponsePromise<DeviceInfo> {
            return await backend.PostForm(deviceURLs.prepare, { mac });
        },
        register: async function (mac: string): ResponsePromise<DeviceInfo> {
            return await backend.PostForm(deviceURLs.register, { mac });
        },
        reinitialize: async function (): ResponsePromise<DeviceInfo> {
            return await backend.Post(deviceURLs.initialize);
        },
        handle: async function (data: CircuitHandlingData): ResponsePromise<CircuitData> {
            return await backend.Post(deviceURLs.circuits, data);
        },
    },
};
