import { Card, Typography, Alert } from '@mui/material';
import { ReactNode } from 'react';

export default function MainPageBox(p: { children?: ReactNode; errorMessage?: string; title?: string; hint?: string }) {
    return (
        <Card
            sx={{
                display: 'grid',
                rowGap: '10px',
                padding: '30px',
            }}
        >
            {p.title && (
                <Typography variant='h5' textAlign='center'>
                    {p.title}
                </Typography>
            )}
            {p.hint && <Typography textAlign='center'>{p.hint}</Typography>}

            {p.errorMessage && (
                <Alert severity='error' sx={{ mb: 2 }}>
                    {p.errorMessage}
                </Alert>
            )}
            {p.children}
        </Card>
    );
}
