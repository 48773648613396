import { Box, Checkbox, FormControlLabel, Link, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { Lock } from 'samson';

import { ActionButton, InputField } from 'react-frontend-components';

import { api } from '../../api/api';
import { useTr, useValidated } from '../../utils/hooks';
import MainPageBox from '../common/MainPageBox';

export const LogInDialog = () => {
    const { t } = useTranslation();
    const tr = useTr();
    const navigate = useNavigate();

    const email = useValidated('');
    const password = useValidated('');

    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    const handleLogin = async () => {
        if (!email.isValid() || !password.isValid()) {
            return;
        }

        email.submit();
        password.submit();

        const result = await api.user.login(email.value, password.value, rememberMe);
        if (result.data) {
            navigate('/device');
            return;
        }

        if (result.errMessage) {
            setErrorMessage(tr(result.errMessage));
            return;
        }

        password.setErr(result.error.formErr['password']);
        email.setErr(result.error.formErr['email']);
    };

    return (
        <MainPageBox title={t('label_login_title')} hint={t('label_login_hint')} errorMessage={errorMessage}>
            <InputField
                type='email'
                color='secondary'
                value={email.value}
                onValueChange={(value: string, hasErr: boolean) => {
                    email.setValue(value, hasErr ? t('error_form_invalid_email') : undefined);
                }}
                onEnter={handleLogin}
                label={t('label_email')}
                size='small'
                validation={(value, modified) => {
                    return !modified || /^.+@.+\.[a-zA-Z]{2,}$/.test(value);
                }}
                helperText={email.error}
                autoFocus
                sx={{ mt: 1 }}
            />
            <InputField
                type='password'
                color='secondary'
                value={password.value}
                onValueChange={(value: string, hasErr: boolean) => {
                    password.setValue(value, hasErr ? t('error_form_invalid_password') : undefined);
                }}
                onEnter={handleLogin}
                label={t('label_password')}
                size='small'
                validation={(value, modified) => !modified || value.length > 0}
                helperText={password.error}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        color='secondary'
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                    />
                }
                label={t('label_remember_me')}
            />
            <ActionButton
                variant='contained'
                onClick={handleLogin}
                size='medium'
                color='primary'
                disabled={!email.isValid() || !password.isValid()}
                fullWidth
            >
                {t('btn_login')}
            </ActionButton>

            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1} sx={{ mt: 1 }}>
                <Box sx={{ display: 'flex' }}>
                    <Lock fontSize='small' sx={{ mr: 1 }} />
                    <Link component={RouterLink} to='/password-reset' variant='body1'>
                        {t('label_forgot_password')}
                    </Link>
                </Box>
            </Stack>

            <Box sx={{ marginTop: '15px', textAlign: 'center' }}>
                <Typography variant='body2'>{t('label_cookie_disclaimer')}</Typography>
                <Typography variant='body2'>
                    <Link component={RouterLink} to='/privacy'>
                        {t('label_gdpr')}
                    </Link>
                </Typography>
            </Box>
        </MainPageBox>
    );
};

export default LogInDialog;
