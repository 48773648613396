import { Box, Stack, Typography } from '@mui/material';
import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRevalidator } from 'react-router-dom';

import {
    ActionButton,
    CreateErrorFromResponse,
    Description,
    FormMessage,
    FormResult,
    InputFormMode,
    InputFormProps,
    InputFormResult,
    InputFormSection,
    ModalDialogConfig,
    ModalFormUpdate,
    ResponseContainsError,
    TrackablePromise,
    useModalForm,
    useNotificationContext,
} from 'react-frontend-components';

import { api } from '../../api/api';
import { CircuitInfo, DeviceInfo, MeterInfo, UserInfo } from '../../api/types';
import { deviceTypeToString } from '../../pages/consts';
import { Consts } from '../../utils';
import { ErrContextNotLoaded, useLoadedCtx, useTr } from '../../utils/hooks';
import { GetDefaultCircuitName, GetDefaultMeterName } from '../../utils/utils';
import { PasswordInputHintCB } from '../common/Utils';

export const CmdDelete = 1;

function getMeterKey(m: MeterInfo): string {
    return 'meter_' + m.idx + '_' + m.station;
}

function getCircuitKey(c: CircuitInfo): string {
    return 'circuit_' + c.nr;
}

function getDlgConfig(t: TFunction): ModalDialogConfig {
    return {
        title: 'Einstellungen',
        buttonsBottomRight: [
            {
                label: t('label_modal_dialog_cancel_button'),
                action: 'reject',
                variant: 'outlined',
                color: 'secondary',
                formAction: '',
            },
            {
                id: 'save',
                label: t('label_modal_dialog_save_button'),
                action: undefined,
                variant: 'contained',
            },
        ],
        buttonsBottomLeft: [
            {
                id: 'delete',
                label: t('label_modal_dialog_delete_account_button'),
                action: 'accept',
                variant: 'outlined',
                color: 'warning',
            },
        ],
    };
}

function getUserSection(t: TFunction, user: UserInfo): InputFormSection {
    return {
        id: 'user',
        label: 'Profil',
        fields: [
            {
                id: 'email',
                type: 'email',
                label: t('label_profile_emails'),
                defaultValue: user.email,
                inputProps: { maxLength: Consts.DefaultMaxLen.default },
            },
            {
                id: 'password_new',
                type: 'password',
                label: t('label_new_password'),
                isOptional: true,
                inputProps: { maxLength: Consts.DefaultMaxLen.default },
                hint: (value) => PasswordInputHintCB(value, t),
            },
            {
                id: 'password_current',
                type: 'password',
                label: t('label_current_password'),
                isOptional: true,
                inputProps: { maxLength: Consts.DefaultMaxLen.default },
                context: {
                    top: (
                        <Typography sx={{ mt: 1 }}>
                            Geben Sie Ihr aktuelles Passwort ein, wenn Sie Ihre E-Mail Adresse oder Ihr Passwort ändern
                            möchten.
                        </Typography>
                    ),
                },
            },
        ],
    };
}

function getDeviceSections(deviceInfo: DeviceInfo, t: TFunction): InputFormSection[] {
    const sections: InputFormSection[] = [
        {
            id: 'circuits',
            label: t('label_name_circuits'),
            fields: deviceInfo.circuits.map((a) => {
                return {
                    id: getCircuitKey(a),
                    type: 'text',
                    label: GetDefaultCircuitName(a.nr),
                    defaultValue: a.name,
                    isOptional: true,
                    inputProps: { maxLength: Consts.DefaultMaxLen.short },
                };
            }),
        },
    ];

    if (deviceInfo.meters.length > 0) {
        sections.push({
            id: 'meters',
            label: t('label_name_meters'),
            fields: deviceInfo.meters.map((a) => {
                return {
                    id: getMeterKey(a),
                    type: 'text',
                    label: GetDefaultMeterName(a.ident_nr),
                    defaultValue: a.name,
                    isOptional: true,
                    inputProps: { maxLength: Consts.DefaultMaxLen.short },
                };
            }),
        });
    }

    sections.push({
        id: 'device',
        label: 'Geräteinformationen',
        fields: [],
        context: {
            top: (_, cmd) => (
                <>
                    <Stack
                        direction={{ sm: 'column', lg: 'row' }}
                        sx={{ alignItems: 'center', justifyContent: 'center' }}
                    >
                        <img src={`/img/controller_${deviceInfo.type}.png`} style={{ maxHeight: '250px' }} />
                        <Box sx={{ ml: 5 }}>
                            <Description
                                items={[
                                    {
                                        label: t('label_device_type'),
                                        value: deviceTypeToString[deviceInfo.type],
                                    },
                                    {
                                        label: t('label_device_firmware'),
                                        value: deviceInfo.firmware,
                                    },
                                    {
                                        label: t('label_device_scheme'),
                                        value: deviceInfo.schema,
                                    },
                                ]}
                            />
                        </Box>
                        <ActionButton
                            variant='outlined'
                            color='warning'
                            onClick={() => cmd('init')}
                            sx={{ ml: { sm: 0, lg: 5 } }}
                        >
                            {t('btn_reinitialize')}
                        </ActionButton>
                    </Stack>
                </>
            ),
        },
    });

    return sections;
}

type ProfileDlgCtx = {
    cmdRef: { id: number };
};

export function useProfileDlg() {
    const { t } = useTranslation();
    const tr = useTr();
    const n = useNotificationContext();

    const refresh = useRevalidator();
    const ctx = useLoadedCtx();
    const [device, setDevice] = useState(ctx.device || null);

    useEffect(() => {
        setDevice(ctx.device);
    }, [ctx.device]);

    if (ctx.user === null) {
        throw ErrContextNotLoaded;
    }

    function createForm(device: DeviceInfo | null): InputFormProps {
        if (ctx === null || ctx.user === null) {
            throw ErrContextNotLoaded;
        }

        const deviceSections = device ? getDeviceSections(device, t) : [];
        return {
            form: {
                sections: [getUserSection(t, ctx.user), ...deviceSections],
                mode: 'accordion' as InputFormMode,
                size: 'small',
            },
        };
    }

    function saveForm(newForm: InputFormProps, input: InputFormResult | undefined, update: ModalDialogConfig) {
        const fr = FormResult(input);
        const frmData: InputFormResult = {
            ...fr.GetSection('user').AsInputResult(),
            ...(device !== null
                ? {
                      device: {
                          circuits: device.circuits.map((a) => {
                              return fr.GetSection('circuits').GetStringDef(getCircuitKey(a), '');
                          }),
                          meters: device.meters.map((a) => {
                              return fr.GetSection('meters').GetStringDef(getMeterKey(a), '');
                          }),
                      },
                  }
                : {}),
        };

        // send the request
        const result = api.user.update(frmData);

        return new TrackablePromise<ModalFormUpdate<ProfileDlgCtx>>((resolve) => {
            result.then((r) => {
                if (r.error === undefined) {
                    n.notificationSuccess(r.data ? t('message_profile_saved_email') : t('message_profile_saved'));
                    refresh.revalidate();
                    resolve({});
                    return;
                }

                if (!r.error.formErr) {
                    n.notificationError('msg_error');
                    resolve({ dialog: update });
                    return;
                }

                // new password error
                if (ResponseContainsError(r.error, 'password_new', 'password_current', 'email')) {
                    newForm.initialError = { user: CreateErrorFromResponse(r.error, (err) => tr(err)) };
                } else {
                    newForm.initialError = CreateErrorFromResponse(r.error, (err) => tr(err));
                }
                resolve({ dialog: update, form: newForm });
            });
        });
    }

    const mf = useModalForm<ProfileDlgCtx>(getDlgConfig(t), createForm(device), (id, config, form, input, dlgCtx) => {
        if (ctx.user === null) {
            throw ErrContextNotLoaded;
        }

        const update = { ...config };
        const newForm = { ...form };

        switch (id) {
            case 'init':
                return new TrackablePromise<ModalFormUpdate<ProfileDlgCtx>>((resolve) => {
                    api.device.reinitialize().then((dir) => {
                        if (dir.error) {
                            resolve({
                                dialog: update,
                                form: {
                                    ...form,
                                    initialError: CreateErrorFromResponse(dir.error, (err) => tr(err)),
                                },
                            });
                            return;
                        }
                        setDevice(dir.data);
                        resolve({
                            dialog: update,
                            form: {
                                ...createForm(dir.data),
                                initialError: FormMessage(t('message_device_init'), 'success'),
                            },
                        });
                    });
                });
            case 'delete':
                if (dlgCtx) {
                    dlgCtx.cmdRef.id = CmdDelete;
                }

                return {
                    dialog: update,
                };
            case 'save':
                return saveForm(newForm, input, update);
        }

        update.buttonsBottomRight![1].disabled = input == undefined || !checkPassword(input, ctx.user.email);

        return { dialog: update };
    });
    return mf;
}

function checkPassword(input: InputFormResult, email: string): boolean {
    const user = FormResult(input).GetSection('user');
    const needPassword = user.GetStringDef('password_new', '') != '' || user.GetStringDef('email', email) != email;
    return user.GetStringDef('password_current', '') != '' || !needPassword;
}
