import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ActionButton, InputField } from 'react-frontend-components';

import { api } from '../../api/api';
import { StringMap } from '../../api/types';
import { useValidated } from '../../utils/hooks';
import MainPageBox from '../common/MainPageBox';
import { PasswordInputHintCB } from '../common/Utils';

export const SignupDialog = (p: { onSubmit: () => void }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [frmErr, setFrmErr] = useState<StringMap>({});

    const email = useValidated('');
    const password = useValidated('');

    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
    const [gdprAccepted, setGdprAccepted] = useState<boolean>(false);

    const handleSignup = async () => {
        if (!email.isValid() || !password.isValid()) {
            return;
        }

        email.submit();
        password.submit();

        const result = await api.user.register(email.value, password.value);
        if (result.error !== undefined) {
            email.setErr(result.error.formErr['email']);
            password.setErr(result.error.formErr['password']);
            setFrmErr(result.error.formErr);
            return;
        }

        p.onSubmit();
    };

    return (
        <MainPageBox title={t('label_signup_title')} hint={t('label_signup_hint')} errorMessage={frmErr['error']}>
            <InputField
                type='email'
                color='secondary'
                value={email.value}
                onValueChange={(value: string, hasErr: boolean) => {
                    email.setValue(value, hasErr ? t('error_form_invalid_email') : undefined);
                }}
                onEnter={handleSignup}
                label={t('label_email')}
                size='small'
                validation={(value, modified) => {
                    return !modified || /^.+@.+\.[a-zA-Z]{2,}$/.test(value);
                }}
                helperText={email.error}
                autoFocus
                sx={{ mt: 1 }}
            />
            <InputField
                type='password'
                color='secondary'
                value={password.value}
                onValueChange={(value: string, hasErr: boolean) => {
                    password.setValue(value, hasErr ? t('error_form_invalid_password') : undefined);
                }}
                onEnter={handleSignup}
                label={t('label_password')}
                size='small'
                validation={(value, modified) => !modified || PasswordInputHintCB(value, t) == undefined}
                helperText={password.error}
                hint={(value) => PasswordInputHintCB(value, t)}
            />
            <InputField
                type='checkbox'
                color='secondary'
                value={termsAccepted ? 'true' : 'false'}
                onValueChange={(v) => setTermsAccepted(v == 'true')}
                label={t('label_terms_accepted')}
                size='small'
                validation={(value, modified) => !modified || value == 'true'}
            ></InputField>
            <InputField
                type='checkbox'
                color='secondary'
                value={gdprAccepted ? 'true' : 'false'}
                onValueChange={(v) => setGdprAccepted(v == 'true')}
                label={t('label_gdpr_accepted')}
                size='small'
                validation={(value, modified) => !modified || value == 'true'}
            />

            <ActionButton
                variant='contained'
                onClick={handleSignup}
                size='medium'
                color='primary'
                disabled={!email.isValid() || !password.isValid() || !termsAccepted || !gdprAccepted}
                fullWidth
            >
                {t('btn_signup')}
            </ActionButton>
            <ActionButton onClick={() => navigate('/')} size='medium' color='secondary' sx={{ mt: 1 }}>
                {t('btn_back_to_home')}
            </ActionButton>
        </MainPageBox>
    );
};

export default SignupDialog;
