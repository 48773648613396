import i18n, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const i18nConfig: InitOptions = {
    ns: ['ui'],

    backend: {
        loadPath: `/i18n/{{ns}}/{{lng}}.json`,
    },

    detection: {
        caches: ['cookie'],
        lookupCookie: 'language',
        convertDetectedLanguage: (_lng) => 'de', // ignore detection, use DE
        //convertDetectedLanguage: (lng) => lng.toLowerCase().replace(/-\w\w$/, ''),
    },

    fallbackLng: 'en',
    fallbackNS: 'ui',

    debug: false,
};

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(i18nConfig);

export default i18n;
