export const modeLabel = {
    auto: 'Automatik',
    day: 'Tag',
    night: 'Nacht',
    standby: 'Standby',
    holiday: 'Urlaub',
};

export const deviceTypeToString: { [key: string]: string } = {
    '5573': '5573',
    '5573-symbol': '5573',
    '5575': '5575',
    '5576': '5576',
    '5578': '5578',
    '5578-e': '5578-E',
    '5579': '5579',
};
