import { StringMap } from '../api/types';

export const BASE_ROUTE: string = 'v2';

export const Consts = {
    DefaultCircuitNames: [
        { cmd: 'control-1', name: 'Heizkreis 1', nr: 10 },
        { cmd: 'control-1', name: 'Heizkreis 1', nr: 11 },
        { cmd: 'control-2', name: 'Heizkreis 2', nr: 20 },
        { cmd: 'control-2', name: 'Heizkreis 2', nr: 21 },
        { cmd: 'control-3', name: 'Heizkreis 3', nr: 30 },
        { cmd: 'control-3', name: 'Heizkreis 3', nr: 31 },
        { cmd: 'control-water', name: 'Trinkwasser', nr: 90 },
        { cmd: 'control-11', name: 'Heizkreis 11', nr: 110 },
        { cmd: 'control-11', name: 'Heizkreis 11', nr: 111 },
        { cmd: 'control-12', name: 'Heizkreis 12', nr: 120 },
        { cmd: 'control-12', name: 'Heizkreis 12', nr: 121 },
        { cmd: 'control-13', name: 'Heizkreis 13', nr: 130 },
        { cmd: 'control-13', name: 'Heizkreis 13', nr: 131 },
    ],
    BackendMessages: {
        locked: 'error_form_account_locked',
        'zu kurz': 'error_form_input_too_short',
        ungültig: 'error_field_ungültig',
        'ungültige E-Mail Adresse': 'error_form_invalid_email',
        'Passwort zu schwach': 'error_form_invalid_password',
    } as StringMap,
    DefaultMaxLen: { default: 255, short: 60 },
};
