import { Alert, Box, Grid2, Typography } from '@mui/material';
import React, { FC, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import { Cog } from 'samson';

import {
    ActionButton,
    LinkBar,
    LinkBarItem,
    useModalDialogContext,
    useNotificationContext,
} from 'react-frontend-components';

import { api } from '../../api/api';
import { LoadingSpinner } from '../../components';
import { CmdDelete, useProfileDlg } from '../../components/authentication/ProfileDialog';
import { useLoadedCtx, useTr } from '../../utils/hooks';
import { DeleteLocalStorageCache } from '../../utils/utils';

interface BaseLayoutProps {
    children?: ReactNode;
    showSettingsBtn?: boolean;
    button?: {
        label: string;
        onClick: () => void;
    };
}

function SettingsBtn() {
    const { notificationError, notificationSuccess } = useNotificationContext();
    const settingsDlg = useProfileDlg();
    const mdc = useModalDialogContext();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const tr = useTr();

    return (
        <ActionButton
            variant='outlined'
            color='secondary'
            onClick={async () => {
                const resultCtx = { cmdRef: { id: 0 } };
                const result = await settingsDlg.show(null, resultCtx);
                switch (true) {
                    case result === undefined:
                        return;
                    case resultCtx.cmdRef.id == CmdDelete:
                        mdc.showConfirmationDialog(t('confirm_account_delete'), async () => {
                            const result = await api.user.delete();
                            if (result.error !== undefined) {
                                notificationError(tr(result.errMessage || ''));
                                return;
                            }
                            setTimeout(() => {
                                notificationSuccess(t('message_account_deleted'));
                                DeleteLocalStorageCache('cache-');
                            }, 500);
                            navigate('/');
                        });
                        break;
                    default:
                        notificationError(JSON.stringify(result));
                }
            }}
        >
            <Cog />
        </ActionButton>
    );
}

export function ErrorScreen() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <Alert severity='error'> {t('error_form_Internal server error.')}</Alert>
            <Box sx={{ mt: 2 }}>
                <Typography>{t('error_form_Internal server error._hint')}</Typography>
                <ActionButton
                    variant='outlined'
                    onClick={() => navigate('/')}
                    size='medium'
                    color='secondary'
                    sx={{ mt: 1 }}
                >
                    {t('btn_back_to_home')}
                </ActionButton>
            </Box>
        </React.Fragment>
    );
}

export const BaseLayout: FC<BaseLayoutProps> = ({ children, showSettingsBtn, button }) => {
    const { t } = useTranslation();
    const ctx = useLoadedCtx();

    const footer: LinkBarItem[] = [
        { action: '/imprint', label: t('label_site_notice') },
        { action: '/terms', label: t('label_terms') },
        { action: '/privacy', label: t('label_gdpr') },
    ];

    return (
        <Grid2 container>
            <Grid2
                size={{ xs: 0, lg: 2 }}
                sx={{
                    backgroundColor: 'white',
                    p: 1,
                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
                }}
            />
            <Grid2
                size={{ xs: 12, lg: 8 }}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    px: 2,
                    backgroundColor: 'background.default',
                }}
            >
                <Box
                    component='header'
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: { xs: 'center', md: 'center' },
                        justifyContent: { md: 'space-between' },
                    }}
                >
                    <a href='/'>
                        <img src='/img/logo.svg' style={{ maxHeight: '92px' }} />
                    </a>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: { xs: 'center', md: 'flex-end' },
                            gap: 1,
                        }}
                    >
                        {showSettingsBtn && <SettingsBtn />}
                        {button && (
                            <ActionButton variant='outlined' color='secondary' onClick={button.onClick}>
                                {t(button.label)}
                            </ActionButton>
                        )}
                    </Box>
                </Box>
                <Box
                    component='main'
                    sx={{
                        flexGrow: 1,
                        maxHeight: 'calc(100vh - 92px - 64px - 15px)',
                        overflowY: 'auto',
                        py: 1,
                        px: 2,
                    }}
                >
                    {ctx && ctx.failure && <ErrorScreen />}
                    {(!ctx || !ctx.failure) && children ? children : <Outlet />}
                </Box>
                <Box
                    component='footer'
                    sx={{
                        height: '64px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <LinkBar
                        items={footer.map((entry) => {
                            return {
                                label: entry.label,
                                action: entry.action,
                            };
                        })}
                        mode='footer'
                        sx={{ lineHeight: '64px' }}
                    />
                </Box>
            </Grid2>
        </Grid2>
    );
};

export function AuthLayout(p: { children: ReactNode; wantDevice?: boolean; wantUser?: boolean }) {
    const { t } = useTranslation();
    const n = useNotificationContext();
    const naviagte = useNavigate();
    const ctx = useLoadedCtx();

    const ready = (!p.wantDevice || ctx.device !== null) && (!p.wantUser || ctx.user !== null);

    useEffect(() => {
        if (!ready) {
            naviagte('/');
        }
    }, [ready]);

    if (!ready) {
        return <LoadingSpinner></LoadingSpinner>;
    }

    return BaseLayout({
        showSettingsBtn: true,
        children: p.children,
        button: {
            label: 'btn_logout',
            onClick: async () => {
                const result = await api.user.logout();
                if (result.error) {
                    n.notificationError(t('label_logout_failed'));
                    return;
                }
                setTimeout(() => {
                    n.notificationSuccess(t('label_logout_success'));
                    DeleteLocalStorageCache('cache-');
                }, 100);
                naviagte('/');
            },
        },
    });
}

export default BaseLayout;
