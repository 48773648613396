import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';

import { Consts } from './consts';
import { DeviceInfo, UserInfo } from '../api/types';

export const ErrContextNotLoaded = new Error('context not loaded');

export function useLoadedCtx() {
    return useLoaderData() as {
        user: UserInfo | null;
        device: DeviceInfo | null;
        failure: boolean;
    };
}

export function useLoadedUser(): UserInfo {
    const ctx = useLoadedCtx();
    if (ctx === null || ctx.user === null) {
        throw ErrContextNotLoaded;
    }
    return ctx.user;
}

export function useLoadedDevice(): DeviceInfo {
    const ctx = useLoadedCtx();
    if (ctx === null || ctx.device === null) {
        throw ErrContextNotLoaded;
    }
    return ctx.device;
}

export function useTr() {
    const { t } = useTranslation();

    return function (msg: string) {
        if (!msg) {
            return msg;
        }
        const btr = Consts.BackendMessages[msg];

        return t(btr || 'error_form_' + msg);
    };
}

export function useValidated<T>(initValue: T, optional?: boolean) {
    const [state, setState] = useState<{
        value: T;
        error: string | undefined;
        editErr: string | undefined;
    }>({ value: initValue, error: undefined, editErr: undefined });

    return {
        ...state,
        setErr: (err: string | undefined) => setState({ ...state, error: err, editErr: err }),
        setValue: (value: T, err?: string) =>
            setState({
                value: value,
                error: undefined,
                editErr: err,
            }),
        submit: () => setState({ ...state, editErr: state.editErr, error: state.editErr }),
        isValid: () => state.error === undefined && state.editErr === undefined && (optional || !!state.value),
    };
}
