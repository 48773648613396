import { Consts } from './consts';

export async function loadStaticURL(url: string): Promise<string | undefined> {
    try {
        const response = await fetch(url);
        if (response.ok) {
            return await response.text();
        }
        return undefined;
    } catch {
        return undefined;
    }
}

export async function loadStaticContent(urls: string[]): Promise<string | undefined> {
    for (const url of urls) {
        const content = await loadStaticURL(url);
        if (content !== undefined) {
            return content;
        }
    }

    return undefined;
}

export function GetDefaultMeterName(identnr: string): string {
    return 'WMZ #' + identnr;
}

export function GetDefaultCircuitName(nr: number): string {
    const value = Consts.DefaultCircuitNames.find((a) => a.nr == nr);
    return (value && value.name) || 'Heizkreis ' + nr;
}

export function DecodeTimeProgram(timeProgram: number[][]): number[][] {
    return timeProgram.map((row) => {
        const br = row.map((nr) => {
            const binaryArray: number[] = [];
            for (let i = 0; i < 16; i++) {
                binaryArray.push((nr >> i) & 1);
            }
            return binaryArray;
        });
        return br.reduce((acc, subArray) => {
            acc.push(...subArray);
            return acc;
        }, []);
    });
}

export function EncodeTimeProgram(decoded: number[][]) {
    return decoded.map((row) => {
        const binaryChunks: number[] = [];
        for (let i = 0; i < row.length; i += 16) {
            let num = 0;
            for (let j = 0; j < 16; j++) {
                if (i + j < row.length) {
                    num |= (row[i + j] & 1) << j;
                }
            }
            binaryChunks.push(num);
        }
        return binaryChunks;
    });
}

export function DeleteLocalStorageCache(keyPrefix: string) {
    const deleteKeys = [] as string[];
    for (let i = 0; i < localStorage.length; i++) {
        const k = localStorage.key(i);
        if (k && k.startsWith(keyPrefix)) {
            deleteKeys.push(k);
        }
    }
    deleteKeys.forEach((k) => localStorage.removeItem(k));
}
