import { Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ActionButton, InputField, useNotificationContext } from 'react-frontend-components';

import { api } from '../api';
import { StringMap } from '../api/types';
import MainPageBox from '../components/common/MainPageBox';
import { PasswordInputHintCB } from '../components/common/Utils';

function ResetPassword(p: { frmErr: StringMap; onSubmit: (password: string) => void }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [password, setPassword] = useState('');

    return (
        <React.Fragment>
            <InputField
                type='password'
                color='secondary'
                value={password}
                onValueChange={(value: string) => {
                    setPassword(value);
                }}
                onEnter={() => p.onSubmit(password)}
                label={t('label_password')}
                size='small'
                validation={(value, modified) => !modified || PasswordInputHintCB(value, t) == undefined}
                hint={(value) => PasswordInputHintCB(value, t)}
            />
            <ActionButton
                variant='contained'
                onClick={() => p.onSubmit(password)}
                size='medium'
                color='primary'
                disabled={PasswordInputHintCB(password, t) != undefined}
                fullWidth
            >
                {t('label_password_reset_title')}
            </ActionButton>
            <ActionButton onClick={() => navigate('/')} size='medium' color='secondary' sx={{ mt: 1 }}>
                {t('btn_back_to_home')}
            </ActionButton>
        </React.Fragment>
    );
}

function ExecBox(p: { onExec: () => void }) {
    useEffect(() => {
        const timer = setTimeout(() => {
            p.onExec();
        }, 100);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    return <></>;
}

export function PageActivate(): JSX.Element {
    const { type, token } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const n = useNotificationContext();

    const [frmErr, setFrmErr] = useState<StringMap>({});
    const [errMessage, setErrMessage] = useState<string | undefined>(undefined);

    function getTitle(): string {
        switch (type) {
            case '2001':
                return t('label_activation_signup');
            case '2002':
                return t('label_activation_password_reset');
            case '2003':
                return t('label_activation_email_change');
            default:
                return t('label_activation_unknown');
        }
    }

    function getHint(): string {
        switch (type) {
            case '2002':
                return t('label_activation_password_reset_hint');
            default:
                return '';
        }
    }

    function getContent() {
        switch (type) {
            case '2001':
            case '2003':
                return <ExecBox onExec={() => handleActivate()} />;
            case '2002':
                return <ResetPassword frmErr={frmErr} onSubmit={(p) => handlePasswordReset(p)} />;
            default:
                return <Alert severity='error'>{t('error_activation_unknown')}</Alert>;
        }
    }

    async function handlePasswordReset(password: string) {
        const result = await api.activate(type!, token!, password);
        result.errMessage && setErrMessage(result.errMessage);
        result.error && setFrmErr(result.error.formErr);

        if (result.error === undefined) {
            setTimeout(() => {
                n.notificationSuccess(t('label_user_password_update'));
            }, 500);
            navigate('/', { state: 'passwordreset' });
        }
    }

    async function handleActivate() {
        const result = await api.activate(type!, token!);
        result.errMessage && setErrMessage(result.errMessage);
        result.error && setFrmErr(result.error.formErr);

        if (!result.error) {
            navigate('/', { state: 'signup' });
        }
    }

    return (
        <MainPageBox
            title={getTitle()}
            hint={getHint()}
            errorMessage={errMessage ? t(`error_form_${errMessage}`) : undefined}
        >
            {getContent()}
        </MainPageBox>
    );
}
