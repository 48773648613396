import { Container, CircularProgress } from '@mui/material';

export const LoadingSpinner = () => {
    return (
        <Container
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                minHeight: '60vh',
            }}
        >
            <CircularProgress />
        </Container>
    );
};
