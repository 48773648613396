import { Typography } from '@mui/material';
import { TFunction } from 'i18next';
import { ReactNode } from 'react';

import { Check } from 'samson';

export const PasswordInputHintCB = (value: string, t: TFunction): ReactNode => {
    const validLength = (value ?? '').length >= 8;
    const containsNumber = /\d/.test(value);
    const validCase = /[a-z]/.test(value) && /[A-Z]/.test(value);
    const specialChar = /[^a-zA-Z\d\s]/.test(value);

    const nrValid = (containsNumber ? 1 : 0) + (validCase ? 1 : 0) + (specialChar ? 1 : 0);
    if (validLength && nrValid >= 2) return undefined;

    const getHint = (label: string, checked: boolean): ReactNode => (
        <Typography variant='body1' sx={{ display: 'flex', ml: 2 }}>
            {t(label)} {checked && <Check color='success' sx={{ ml: 1 }} />}
        </Typography>
    );

    return (
        <>
            <Typography variant='subtitle1'>{t('label_password_properties')}</Typography>
            {getHint('label_password_length', validLength)}
            {getHint('label_password_numbers', containsNumber)}
            {getHint('label_password_upper_and_lower_case', validCase)}
            {getHint('label_password_special_characters', specialChar)}
            <Typography variant='subtitle1'>{t('label_password_hints')}</Typography>
            {getHint('label_password_hint_no_personal_information', false)}
            {getHint('label_password_hint_no_trivial_password', false)}
        </>
    );
};
