import { useState } from 'react';

import SignupDialog from './SignupDialog';
import { MarkdownText } from '../common';
import MainPageBox from '../common/MainPageBox';

function SignupDoneCard() {
    return (
        <MainPageBox>
            <MarkdownText source='signup_done'></MarkdownText>
        </MainPageBox>
    );
}
export function SignupCard() {
    const [isRegistered, setRegistered] = useState<boolean>(false);

    return isRegistered ? <SignupDoneCard /> : <SignupDialog onSubmit={() => setRegistered(true)}></SignupDialog>;
}
