import { FC, ReactNode, createContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { APIError, loadStaticJSON, useLoad } from 'react-frontend-components';

import { saveCurrentRoute } from '.';
import { AppInfo } from '../api/types';

interface AppContextProps {
    appInfo: AppInfo;
}

const AppContext = createContext<AppContextProps | null>(null);

interface AppContextProviderProps {
    children: ReactNode;
}

export const AppContextProvider: FC<AppContextProviderProps> = ({ children }) => {
    const { i18n } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname != '/') {
            saveCurrentRoute(`${location.pathname}${location.search}`);
        }
    }, [location.pathname, location.search]);

    const appInfo = useLoad<AppInfo>(async () => {
        const result = await loadStaticJSON<AppInfo>([`info/meta_${i18n.language}.json`, `info/meta_en.json`]);
        return result ? { data: result, errMessage: undefined, error: undefined } : APIError('unable to load the file');
    });

    return (
        <AppContext.Provider value={appInfo && appInfo.data ? { appInfo: appInfo.data } : null}>
            {appInfo && appInfo.data && children}
        </AppContext.Provider>
    );
};
