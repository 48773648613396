import { Box, Link, SxProps } from '@mui/material';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { LoadingIndicator, MessageBox } from 'react-frontend-components';

interface MarkdownTextProps {
    source: string;
    sx?: SxProps;
}

export const MarkdownText: FC<MarkdownTextProps> = ({ source, sx }) => {
    const { i18n } = useTranslation();

    const [markdown, setMarkdown] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        const fetchMarkdown = async () => {
            try {
                const response = await axios.get(`/i18n/static/${i18n.language}/${source}.md`);
                setMarkdown(response.data);
            } catch (err) {
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchMarkdown();
    }, [source]);

    return (
        <>
            <LoadingIndicator isLoading={loading} />
            {error && <MessageBox title='Fehler' message='Inhalt konnte nicht geladen werden.' color='error' />}
            {!error && (
                <Box sx={{ ...sx }}>
                    <Markdown
                        remarkPlugins={[remarkGfm]}
                        components={{
                            a: (e) => {
                                return (
                                    <Link href={e.href}>
                                        <u>{e.children}</u>
                                    </Link>
                                );
                            },
                            img: (e) => {
                                return <img src={e.src} alt={e.alt} style={{ maxWidth: '100%', maxHeight: '100%' }} />;
                            },
                        }}
                    >
                        {markdown}
                    </Markdown>
                </Box>
            )}
        </>
    );
};
